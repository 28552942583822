import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  redirect,
  useNavigate
} from 'react-router-dom';

import { __ADMIN__ } from '@vz/env';

import {
  MainPage,
  BlogPostPage,
  DemoPage,
  NotePage,

  // Demos/Projects
  IphonePage,
  LumiaPage,

  //  Generic
  NotFoundPage
} from '@vz/ui/pages/lazy';
import {
  BlogPostEditPage,
  DemoEditPage,
  NoteEditPage
} from '@vz/ui/pages/(admin)/lazy';

// import PageView from '../components/Analytics/PageView';
// import NotFound from '../page/NotFound';

// const GATrack = ({ location: { pathname, search, hash } }) => (
//   <PageView url={`${pathname}${search}${hash}`} />
// );

const SlashRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.endsWith('/')) {
      const pathname = location.pathname.slice(0, -1);
      navigate(`${pathname}${location.search}${location.hash}`, {
        replace: true
      });
    }
  }, [location.pathname, navigate]);
  return null;
};

export const Pages = () => (
  <>
    <Routes>
      <Route path="*" element={<SlashRedirect />} />
    </Routes>
    <Routes>
      {/* <Route path="*" component={GATrack} /> */}

      {/* <Redirect path="/:url*(/+)" exact to={pathname.slice(0, -1)} /> */}

      {/* Root routes */}

      <Route path="/" element={<MainPage />} />
      {/* <Route path="/blog"  /> */}
      {/* <Route path="/blog/page/:number" component={BlogList} /> */}
      {/* <Route path="/blog/tag/:tag" component={BlogList} /> */}

      {__ADMIN__ && (
        <>
          <Route path="/blog/edit/:id" element={<BlogPostEditPage />} />
          <Route path="/notes/edit/:id" element={<NoteEditPage />} />
          <Route path="/demos/edit/:id" element={<DemoEditPage />} />
        </>
      )}

      {/* {__ADMIN__ && (
      <Route path="/blog/edit/:id" element={<BlogPostEditPage />} />
    )} */}
      <Route path="/blog" element={<></>} />
      <Route path="/blog/:url" element={<BlogPostPage />} />

      <Route path="/demos" element={<></>} />
      <Route path="/demos/:url" element={<DemoPage />} />

      <Route path="/notes" element={<></>} />
      <Route path="/notes/:id/*" element={<NotePage />} />

      {/* <Route path="/contacts" exact component={Contacts} /> */}

      {/* Projects */}
      <Route path="/iphone" element={<IphonePage />} />
      <Route path="/lumia" element={<LumiaPage />} />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </>
);
