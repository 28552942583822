import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Twitter as IconTwitter,
  Instagram as IconInstagram,
  GitHub as IconGitHub,
  Linkedin as IconLinkedin,
  Facebook as IconFacebook,
  Home as IconHome,
  User as IconUser,
  List as IconList,
  Rss as IconRSS,
  Menu as IconMenu,
  X as IconX,
  Bookmark as IconBookmark,
  Clipboard as IconClipboard,
  FileText as IconFileText,
  Code as IconCode,
  ChevronRight as IconChevronRight,
  ChevronLeft as IconChevronLeft,
  ExternalLink as IconExternalLink,
  CornerDownRight as IconCornerDownRight,
  type Icon
} from 'react-feather';

import cx from '@3ui/cx';

import { Link } from '../Link';
import { BlogList } from '../BlogList';
import { DemosList } from '../DemosList';
import { PageTree } from '../PageTree';
// import { OnNavigate } from '../Client/OnNavigate';

import { NestedNav } from './NestedNav';

import S from './Sidebar.module.css';

interface LinkOptions {
  activeCheckMode?: 'exact' | 'start';
  nav?: {
    id: string;
    depth?: number;
    component: () => JSX.Element | null;
    width?: number;
  };
}

const LINKS: [Icon, string, string, LinkOptions?][] = [
  [IconHome, '/', 'Home', { activeCheckMode: 'exact' }],
  // [IconUser, '/about', 'About'],
  // [IconList, '/changelog', 'Changelog'],
  [
    IconClipboard,
    '/blog',
    'Blog',
    {
      nav: {
        id: 'blog',
        component: BlogList
        // width: 400
      }
    }
  ],
  [
    IconFileText,
    '/notes',
    'Notes',
    {
      nav: {
        id: 'notes',
        component: PageTree
        // width: 400
      }
    }
  ],
  [
    IconCode,
    '/demos',
    'Demos',
    {
      nav: {
        id: 'demos',
        component: DemosList,
        width: 400
      }
    }
  ]
  // [IconBookmark, '/bookmarks', 'Bookmarks']
];

const PROJECTS: [
  Icon | string | null,
  string,
  string | (() => React.ReactNode)
][] = [
  [
    () => <>🧩</>,
    'https://3ui.zubach.com',
    () => (
      <>
        <code>@3ui</code> - The UI
      </>
    )
  ]
  // [IconCornerDownRight, 'https://3ui.zubach.com/@3ui/bumper', () => <>Bumper</>]
  // [IconCornerDownRight, null, 'RRB']
  // ['', 'https://www.blincheck.com', 'Blinchek']
];

const DEMOS: [
  Icon | string | null,
  string,
  string | (() => React.ReactNode)
][] = [
  [() => <>📱</>, '/iphone', 'iPhone 4 in CSS'],
  [null, '/lumia', 'Lumia in CSS'],
  [null, '/blog/phones-in-css', 'Phones in CSS']
];

const ONLINE: [Icon, string, string][] = [
  [IconGitHub, 'https://github.com/vasyl-zubach', 'GitHub'],
  [IconLinkedin, 'https://www.linkedin.com/in/vasyl-zubach/', 'LinkedIn'],
  [IconTwitter, 'https://twitter.com/Vasyl_Zubach', 'Twitter']
  // [IconFacebook, 'https://facebook.com/vasyl.zubach', 'Facebook'],
  // [IconInstagram, 'https://instagram.com/vasyl.zubach/', 'Instagram']
];

interface Props {
  className?: string;
}

function calculateState(pathname) {
  const selectedLink = LINKS.find(
    (item) =>
      item[3]?.nav &&
      (pathname !== '/'
        ? String(pathname).startsWith(item[1])
        : item[1] === pathname)
  );
  const cached = selectedLink && calculateState.__cache[selectedLink[1]];
  if (cached) {
    return cached;
  }
  const depth = selectedLink?.[3]?.nav ? selectedLink[3].nav.depth || 1 : 0;

  const Nav = selectedLink?.[3]?.nav?.component;

  const state = {
    selectedLink,
    depth,
    LinkNav:
      selectedLink && Nav
        ? () => (
            <NestedNav title={selectedLink[2]}>
              <Nav />
            </NestedNav>
          )
        : null
  };
  if (selectedLink) {
    calculateState.__cache[selectedLink[1]] = state;
  }
  return state;
}

calculateState.__cache = {};

const Anchor = ({ to, children, ...props }) => {
  return to.startsWith('https://') ? (
    <a href={to} target="_blank" {...props}>
      {children}
      <span className={S.linkIcon}>
        <IconExternalLink
          width={14}
          height={14}
          strokeWidth={2}
          opacity={0.8}
        />
      </span>
    </a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

const NavSection = ({ links, title }) => {
  return !links.length ? null : (
    <>
      <p className={S.title}>{title}</p>
      <ul className={S.list}>
        {links.map(([TheIcon, href, TheTitle], idx) => (
          <li key={idx}>
            <Anchor to={href} className={`${S.link} ${S.icon} ${S.external}`}>
              {typeof TheIcon === 'string' ? (
                <picture className={S.linkIcon}>
                  <img
                    src={TheIcon}
                    width={24}
                    height={24}
                    alt={typeof TheTitle === 'string' ? TheTitle : ''}
                  />
                </picture>
              ) : (
                TheIcon && (
                  <span className={S.linkIcon}>
                    <TheIcon width={16} height={16} />
                  </span>
                )
              )}
              <span className={S.linkTitle}>
                {typeof TheTitle === 'string' ? TheTitle : <TheTitle />}
              </span>
            </Anchor>
          </li>
        ))}
      </ul>
    </>
  );
};

export const Sidebar = ({ className }: Props) => {
  const { pathname } = useLocation();
  const [state, setState] = useState(calculateState(pathname));
  const [isOpen, setIsOpen] = useState(false);

  const { selectedLink, depth, LinkNav } = state;

  useEffect(() => {
    const newState = calculateState(pathname);
    if (
      state.selectedLink === newState.selectedLink &&
      state.depth === newState.depth &&
      state.LinkNav === newState.LinkNav
    ) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      ...newState,
      LinkNav:
        newState.depth === 0
          ? prevState.LinkNav || newState.LinkNav
          : newState.LinkNav
    }));
  }, [pathname, state.LinkNav, state.depth, state.selectedLink, setState]);

  const style = selectedLink?.[3]?.nav?.width
    ? { width: selectedLink?.[3]?.nav?.width }
    : undefined;

  const removeNestedNav = useCallback(() => {
    setState((prevState) => ({ ...prevState, LinkNav: null }));
  }, [setState]);

  const toggleMenu = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, [setIsOpen]);

  return (
    <>
      <button
        role="button"
        className={`${S.btn} ${S.menuBtn}`}
        onClick={toggleMenu}
      >
        <IconMenu width={16} height={16} />
      </button>
      <nav className={cx(S.root, className)} style={style} data-open={isOpen}>
        <div className={S.header}>
          <Link to="/" className={S.avatar}>
            <picture>
              <img
                src="/img/ava-mini--vasyl-zubach.png"
                srcSet="/img/ava-mini--vasyl-zubach@2x.png 2x, /img/ava-mini--vasyl-zubach@3x.png 3x"
                alt="Vasyl Zubach"
                width={40}
                height={40}
              />
            </picture>
            Vasyl Zubach
          </Link>
          <button
            role="button"
            className={`${S.btn} ${S.closeBtn}`}
            onClick={toggleMenu}
          >
            <IconX width={16} height={16} />
          </button>
        </div>

        <div className={S.content}>
          <section
            data-depth={0}
            className={S.section}
            data-position={depth === 0 ? 'intial' : 'left'}
          >
            <ul className={S.list}>
              {LINKS.map(([Icon, href, title, options], idx) => (
                <li key={idx}>
                  <Link
                    to={href}
                    className={S.link}
                    activeCheckMode={options?.activeCheckMode}
                  >
                    {Icon && (
                      <span className={S.linkIcon}>
                        <Icon width={16} height={16} />
                      </span>
                    )}
                    <span className={S.linkTitle}>{title}</span>
                    {options?.nav ? (
                      <span className={S.linkIcon}>
                        <IconChevronRight width={16} height={16} />
                      </span>
                    ) : null}
                  </Link>
                </li>
              ))}
            </ul>

            <br />

            <ul className={S.list}>
              <li>
                <Link to={'/notes/8/stand-with-ukraine'} className={S.link}>
                  <span className={S.linkIcon}>
                    <img
                      src="/img/ukraine-flag.svg"
                      width={16}
                      alt="Stand With Ukraine"
                    />
                  </span>
                  <span className={S.linkTitle}>#StandWithUkraine</span>
                </Link>
              </li>
            </ul>

            <NavSection title={'Projects'} links={PROJECTS} />
            <NavSection title={'Notable demos'} links={DEMOS} />
          </section>

          <section
            className={`${S.section} ${S.nestedNav}`}
            data-depth={1}
            data-position={depth === 1 ? 'intial' : 'right'}
            onTransitionEnd={
              depth === 0 && LinkNav ? removeNestedNav : undefined
            }
            data-z-index={LinkNav ? 1 : -1}
          >
            {LinkNav && <LinkNav />}
          </section>
        </div>

        <section className={S.footer}>
          {ONLINE.map(([Icon, href, title], idx) => (
            <a
              href={href || ''}
              className={S.icon}
              key={idx}
              target="_blank"
              rel="nofollow noreferrer"
              title={title}
            >
              <Icon width={20} height={20} />
            </a>
          ))}
          <a href={'/rss.xml'} className={`${S.icon}`}>
            <IconRSS width={20} height={20} />
          </a>
          <Link
            to={'/notes/8/stand-with-ukraine'}
            className={`${S.icon} ${S.ua}`}
          >
            <img
              src="/img/ukraine-flag.svg"
              width={26}
              alt="Stand With Ukraine"
            />
          </Link>
        </section>

        {/* <Link className={S.rss} to="/rss" /> */}
      </nav>
      <div className={S.blanket} onClick={toggleMenu} />
    </>
  );
};
