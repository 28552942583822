import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import {
  ChevronRight as IconChevronRight,
  ChevronsDown as IconChevronDown,
  Edit3 as IconEdit3
} from 'react-feather';

import { __ADMIN__ } from '@vz/env';

import { getSlug } from '../../helpers/slug';

import { Link } from '../Link';
import { LinkEdit } from '../Link/LinkEdit';

import S from './PageTree.module.css';
import { useExpand } from './useExpand';
import { type DataEntry } from './types';

interface Props {
  id: number;
  url?: string | null;
  title?: string;
  // parentId: number | null;
  data: DataEntry[];
  isPublished?: boolean;
  Branch: (props: {
    parentId: number | null;
    data: DataEntry[];
  }) => JSX.Element | null;
}

export const Item = ({ id, url, title, isPublished, data, Branch }: Props) => {
  const hasChildren = data.some((item) => item.parent_id === id);
  const [isExpanded, toggleExpand] = useExpand({ hasChildren, id, data });

  return (
    <li className={S.branch}>
      <Link
        className={S.link}
        data-id={id}
        data-expanded={isExpanded || undefined}
        data-published={isPublished}
        to={`/notes/${id}/${url || getSlug(title)}`}
        onClick={(!isExpanded && toggleExpand) || undefined}
      >
        <>
          {toggleExpand && (
            <span className={S.iconWrapper} onClick={toggleExpand}>
              <IconChevronRight className={S.icon} width={14} height={14} />
            </span>
          )}
          {title || `#${id}`}
        </>
      </Link>
      {__ADMIN__ && <LinkEdit to={`/notes/edit/${id}`} className={S.edit} />}
      {toggleExpand && isExpanded && <Branch parentId={id} data={data} />}
    </li>
  );
};
