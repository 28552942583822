export const getScrollParent = (el) => {
  if (!el) {
    return null;
  }

  if (el.scrollHeight > el.clientHeight) {
    return el;
  } else {
    return getScrollParent(el.parentNode);
  }
};

export const scrollIntoView = ({
  el,
  parent = getScrollParent(el),
  gap = { top: 0, bottom: 0 }
}) => {
  if (!el || !parent) {
    return;
  }
  gap = { top: gap.top || 0, bottom: gap.bottom || 0 };

  // console.log({ el, parent });

  const parentRect = parent.getBoundingClientRect();
  const elRect = el.getBoundingClientRect();

  const isUp = parentRect.top >= elRect.top - gap.top;
  const isDown = parentRect.bottom <= elRect.bottom + gap.bottom;

  if (!isUp && !isDown) {
    return;
  }

  parent.scrollTop =
    parent.scrollTop +
    parentRect.height / 2 +
    elRect.height / 2 +
    elRect.top -
    parentRect.bottom;
};
