// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout-root__L3Zlc {\n  width: 100%;\n  height: 100%;\n  display: flex;\n}\n\n.Layout-sidebar__L3Zlc {\n  background: rgba(246, 248, 255, 1);\n  width: 300px;\n  height: 100%;\n  /* position: relative; */\n  overflow: visible;\n  flex-grow: 0;\n  flex-shrink: 0;\n  border-right: 1px solid rgba(0, 0, 0, 0.1);\n  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05),\n    inset -1px 0 0 rgba(255, 255, 255, 0.5);\n}\n\n.Layout-content__L3Zlc {\n  margin: 0 auto;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n\n  & > section {\n    margin: 0 auto;\n    padding: 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../ui/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,YAAY;EACZ,YAAY;EACZ,wBAAwB;EACxB,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,0CAA0C;EAC1C;2CACyC;AAC3C;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,cAAc;;EAEd;IACE,cAAc;IACd,aAAa;EACf;AACF","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  display: flex;\n}\n\n.sidebar {\n  background: rgba(246, 248, 255, 1);\n  width: 300px;\n  height: 100%;\n  /* position: relative; */\n  overflow: visible;\n  flex-grow: 0;\n  flex-shrink: 0;\n  border-right: 1px solid rgba(0, 0, 0, 0.1);\n  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05),\n    inset -1px 0 0 rgba(255, 255, 255, 0.5);\n}\n\n.content {\n  margin: 0 auto;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n\n  & > section {\n    margin: 0 auto;\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Layout-root__L3Zlc",
	"sidebar": "Layout-sidebar__L3Zlc",
	"content": "Layout-content__L3Zlc"
};
export default ___CSS_LOADER_EXPORT___;
