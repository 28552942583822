import React, { useLayoutEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import window from 'window-or-global';

import { __ADMIN__ } from '@vz/env';

import S from './PageTree.module.css';
import { useSWR } from '../../hooks/useSWR';
import { scrollIntoView } from '../../helpers/dom';

import { Branch } from './Branch';
import ErrorBoundary from '../../pkg/ErrorBoundary';
import Spinner from '../Spinner';

import Sa from './Admin.module.css';
import { AddButton } from '../Admin/AddButton';

export const PageTree = () => {
  const {
    data: apiResponse,
    error,
    isLoading
  } = useSWR('/api/notes/list', {
    initial: window.__INITIAL_DATA__?.['/api/notes/list']
  });
  const { pathname } = useLocation();

  const rootRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!rootRef.current) {
      return;
    }
    // rootRef.current
    //   ?.querySelector('[data-active="true"]')
    //   ?.scrollIntoView({ behavior: 'auto', block: 'nearest' });

    const el = rootRef.current.querySelector('[data-active="true"]');
    scrollIntoView({ el });
  }, [rootRef.current]);

  const data = apiResponse?.data || null;

  if (isLoading && !data) {
    return <Spinner size={24} className={S.spinner} />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <>
      {pathname === '/notes' && <Navigate to="/notes/1/readme.md" replace />}
      {__ADMIN__ && (
        <AddButton
          api="/api/admin/notes/add"
          goto="/notes/edit/{{id}}"
          refreshApi="/api/notes/list"
          className={Sa.add}
        />
      )}
      <div className={S.root} data-test-id="notes-tree" ref={rootRef}>
        <ErrorBoundary>
          <Branch parentId={null} data={data} />
        </ErrorBoundary>
      </div>
    </>
  );
};
