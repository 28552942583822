import React, { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import S from './Layout.module.css';

export const Layout = ({ sidebar: Sidebar, children }) => {
  const location = useLocation();
  const refContent = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refContent.current) {
      const el = refContent.current;
      setTimeout(() => {
        el.scrollTop = 0;
      }, 0);
    }
  }, [location.pathname, refContent.current]);
  return (
    <div className={S.root}>
      <Sidebar className={S.sidebar} />
      <div className={S.content} ref={refContent}>
        {children}
      </div>
    </div>
  );
};
