import {
  useState,
  useEffect,
  useCallback,
  type SyntheticEvent,
  useMemo
} from 'react';
import memoizeOne from 'memoize-one';
import { useLocation } from 'react-router-dom';

import { type DataEntry } from './types';

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

const LS_NAME = 'notes';
const getLSData = () => {
  const storage = window?.localStorage;
  try {
    return JSON.parse(storage.getItem(LS_NAME) || '[]');
  } catch (e) {
    /** noop */
  }
  return null;
};

const updateLSData = (ids?: number[]) => {
  if (!ids) {
    const container = window.document.querySelector(
      '[data-test-id="notes-tree"]'
    );
    if (!container) {
      return;
    }
    const links: HTMLAnchorElement[] = Array.from(
      container.querySelectorAll('a[data-id][data-expanded]')
    );
    ids = links
      .map((el) => (el.dataset.id ? parseInt(el.dataset.id) : null))
      .filter(Boolean) as number[];
  }

  window.localStorage.setItem(LS_NAME, JSON.stringify(ids));
};

const getParrentChain = memoizeOne((id, data) => {
  let currentId = id;
  const chain: number[] = [];
  while (currentId !== null) {
    const parentId =
      data.find((item) => item.id === currentId)?.parent_id || null;

    parentId && chain.push(parentId);
    currentId = parentId;
  }
  return chain;
});

// TODO: get rid of dependency on pathName
const getExpandedNodes = memoizeOne((pathname, data) => {
  const noteIdStr = pathname?.startsWith('/notes/edit/')
    ? pathname?.split('/')?.[3]
    : pathname?.split('/')?.[2];
  const currentNoteId = noteIdStr ? parseInt(noteIdStr, 10) : null;

  const parentChain = getParrentChain(currentNoteId, data);
  const storedData = getLSData();
  const uniqueIds = [...parentChain, ...storedData].filter(onlyUnique);

  setTimeout(() => {
    updateLSData(uniqueIds);
  }, 0);
  return uniqueIds;
});

export const useExpand = ({
  hasChildren,
  data,
  id
}: {
  hasChildren: boolean;
  data: DataEntry[];
  id: number;
}): [
  boolean,
  null | ((event: SyntheticEvent<HTMLElement | SVGElement>) => void)
] => {
  const { pathname } = useLocation();
  const expandedNodes = getExpandedNodes(pathname, data);
  const [isExpanded, setIsExpanded] = useState(expandedNodes.includes(id));

  const toggleExpand = useCallback(
    (event: SyntheticEvent<HTMLElement | SVGElement>) => {
      const target = event.currentTarget;
      const isValidLink =
        target.tagName?.toLowerCase() === 'a' && target.getAttribute('href');
      if (!isValidLink) {
        event.preventDefault();
        event.stopPropagation();
      }

      setIsExpanded((prev) => !prev);
      setTimeout(() => {
        updateLSData();
      }, 100);
    },
    [setIsExpanded]
  );

  return hasChildren ? [isExpanded, toggleExpand] : [false, null];
};
