import React, { useMemo } from 'react';
// import {
//   ChevronRight as IconChevronRight,
//   ChevronsDown as IconChevronDown
// } from 'react-feather';

// import { getSlug } from '../../helpers/slug';

// import { Link } from '../Link';
import { Item } from './Item';

import S from './PageTree.module.css';

import { DataEntry } from './types';

interface Props {
  parentId?: number | null;
  data: DataEntry[];
}

export const Branch = ({ parentId, data }: Props) => {
  const levelData = useMemo(
    () => data.filter((item) => item.parent_id === parentId),
    [data, parentId]
  );

  if (!levelData) {
    return null;
  }

  return (
    <ul className={S.branch} data-parent-id={String(parentId)}>
      {levelData.map(({ parent_id, published, ...itemProps }, idx) => (
        <Item
          {...itemProps}
          key={idx}
          isPublished={typeof published === 'undefined' || published === true}
          // parentId={parent_id || null}
          data={data}
          Branch={Branch}
        />
      ))}
    </ul>
  );
};
