// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner-root__L3Zlc,\n.Spinner-root__L3Zlc:after {\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n}\n.Spinner-root__L3Zlc {\n  margin-left: auto;\n  margin-right: auto;\n  font-size: 50px;\n  position: relative;\n  text-indent: -9999em;\n  border-top: 5px solid rgba(0, 0, 0, 0.1);\n  border-right: 5px solid rgba(0, 0, 0, 0.1);\n  border-bottom: 5px solid rgba(0, 0, 0, 0.1);\n  border-left: 5px solid rgba(0, 0, 0, 0.3);\n  transform: translateZ(0);\n  animation: Spinner-spin__L3Zlc 1.1s infinite linear;\n}\n\n@keyframes Spinner-spin__L3Zlc {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://./../ui/components/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,oBAAoB;EACpB,wCAAwC;EACxC,0CAA0C;EAC1C,2CAA2C;EAC3C,yCAAyC;EACzC,wBAAwB;EACxB,mDAAoC;AACtC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".root,\n.root:after {\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n}\n.root {\n  margin-left: auto;\n  margin-right: auto;\n  font-size: 50px;\n  position: relative;\n  text-indent: -9999em;\n  border-top: 5px solid rgba(0, 0, 0, 0.1);\n  border-right: 5px solid rgba(0, 0, 0, 0.1);\n  border-bottom: 5px solid rgba(0, 0, 0, 0.1);\n  border-left: 5px solid rgba(0, 0, 0, 0.3);\n  transform: translateZ(0);\n  animation: spin 1.1s infinite linear;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Spinner-root__L3Zlc",
	"spin": "Spinner-spin__L3Zlc"
};
export default ___CSS_LOADER_EXPORT___;
