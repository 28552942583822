import React from 'react';

import {
  Home as IconHome,
  ChevronLeft as IconChevronLeft
} from 'react-feather';

import { Link as A } from 'react-router-dom';
import S from './NestedNav.module.css';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const NestedNav = ({ children, title }: Props) => {
  return (
    <>
      <div className={S.header}>
        <A to="/" className={S.btnBack}>
          <IconChevronLeft width={16} height={16} />
          <IconHome width={16} height={16} />
        </A>
        <div className={S.title}>{title}</div>
      </div>
      <div className={S.content}>{children}</div>
    </>
  );
};
