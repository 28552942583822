import React from 'react';
import { Link as RouterLink, type LinkProps } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const isActive = (pathname: string, href: string, id) => {
  if (pathname?.startsWith(href)) {
    const nextChar = pathname.slice(href.length, href.length + 1);
    if (nextChar === '/' || nextChar === '?' || nextChar === '') {
      return true;
    }
  }
  if (id && pathname?.endsWith(`/edit/${id}`)) {
    return true;
  }
  return false;
};

type Child = JSX.Element | null | string;

export const Link = ({
  activeCheckMode = 'start',
  ...props
}: LinkProps & {
  children: Child | Child[];
  activeCheckMode?: 'exact' | 'start';
  className?: string;
  [key: string]: unknown;
}) => {
  const { pathname } = useLocation();
  return (
    <RouterLink
      {...props}
      data-active={
        activeCheckMode === 'exact'
          ? (pathname || '') === String(props.to)
          : isActive(pathname || '', String(props.to), props.id).toString() ||
            'false'
      }
    />
  );
};
