import React, { memo, useEffect, useLayoutEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import window from 'window-or-global';
import memoizeOne from 'memoize-one';

import { __ADMIN__ } from '@vz/env';

import { useSWR } from '../../hooks/useSWR';
import { scrollIntoView } from '../../helpers/dom';

import { Link } from '../Link';
import Spinner from '../Spinner';

import S from './DemosList.module.css';
import Sa from './Admin.module.css';
import { LinkEdit } from '../Link/LinkEdit';
import { AddButton } from '../Admin/AddButton';

const groupByYear = memoizeOne((data) =>
  data.reduce((memo, item) => {
    const YYYY = parseInt(item.date_published.split('-')[0], 10);
    if (!memo[YYYY]) {
      memo[YYYY] = [];
    }
    memo[YYYY].push(item);
    return memo;
  }, {})
);

export const DemosList = () => {
  const {
    data: apiResponse,
    error,
    isLoading
  } = useSWR('/api/demos-list', {
    initial: window.__INITIAL_DATA__?.['/api/demos-list']
  });
  const { pathname } = useLocation();

  const rootRef = useRef<HTMLDivElement>(null);

  const data = apiResponse?.data;

  useLayoutEffect(() => {
    // rootRef.current
    //   ?.querySelector('[data-active="true"]')
    //   ?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    if (!rootRef.current) {
      return;
    }
    scrollIntoView({
      el: rootRef.current.querySelector('[data-active="true"]')
    });
  }, [rootRef.current]);

  if (isLoading && !data) {
    return <Spinner size={24} className={S.spinner} />;
  }

  if (error && !data) {
    return <>...error</>;
  }

  if (data?.length === 0) {
    return <>no data</>;
  }

  const grouppedData = groupByYear(data);

  const redirectTo =
    pathname !== '/demos'
      ? null
      : apiResponse.data.filter((i) => i.published !== 0)[0]?.url ||
        apiResponse.data[0]?.url;

  return (
    <>
      {redirectTo && <Navigate to={`/demos/${redirectTo}`} replace />}
      {__ADMIN__ && (
        <AddButton
          api="/api/admin/demos/add"
          goto="/demos/edit/{{id}}"
          refreshApi="/api/demos-list"
          className={Sa.add}
        />
      )}
      <div className={S.root} ref={rootRef}>
        {Object.keys(grouppedData)
          .sort()
          .reverse()
          .map((YYYY, idxUl) => (
            <React.Fragment key={idxUl}>
              <div className={S.year}>{YYYY}</div>
              <ul key={idxUl} className={S.ul}>
                {grouppedData[YYYY].map((item, idxLi) => (
                  <li key={idxLi} className={S.li}>
                    <Link
                      to={`/demos/${item.url}`}
                      className={S.link}
                      data-published={
                        item.published === 0 ? 'false' : undefined
                      }
                    >
                      <span className={S.title}>{item.title}</span>
                      <picture>
                        <img
                          src={`/api/img/demos/${item.url}/index.png`}
                          alt={item.title}
                        />
                      </picture>
                    </Link>
                    {__ADMIN__ && <LinkEdit to={`/demos/edit/${item.id}`} />}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        {false &&
          data.map((item, idx) => (
            <li key={idx} className={S.li}>
              <Link
                to={`/demos/${item.url}`}
                className={S.link}
                data-published={item.published === 0 ? 'false' : undefined}
              >
                <span className={S.title}>{item.title}</span>
                <picture>
                  <img
                    src={`/api/img/demos/${item.url}/index.png`}
                    alt={item.title}
                  />
                </picture>
              </Link>
              {__ADMIN__ && <LinkEdit to={`/demos/edit/${item.id}`} />}
            </li>
          ))}
      </div>
    </>
  );
};
