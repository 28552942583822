import React, { lazy, Suspense } from 'react';

import Spinner from '../components/Spinner';

export { default as NotFoundPage } from './__not-found';

const Fallback = () => (
  <Spinner size={24} style={{ top: '50%', marginTop: -12 }} />
);

const LazyMainPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      /* webpackChunkName: "page/index" */
      '@vz/ui/pages/index'
    )
);

export const MainPage = () => (
  <Suspense fallback={<Fallback />}>
    <LazyMainPage />
  </Suspense>
);

const LazyBlogPostPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      /* webpackChunkName: "page/blogpost" */
      '@vz/ui/pages/blog/[slug]'
    )
);
export const BlogPostPage = () => (
  <Suspense fallback={<Fallback />}>
    <LazyBlogPostPage />
  </Suspense>
);

const LazyDemoPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      /* webpackChunkName: "page/demo" */
      '@vz/ui/pages/demos/[slug]'
    )
);
export const DemoPage = () => (
  <Suspense fallback={<Fallback />}>
    <LazyDemoPage />
  </Suspense>
);

const LazyNotePage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      /* webpackChunkName: "page/note" */
      '@vz/ui/pages/notes/[id]'
    )
);
export const NotePage = () => (
  <Suspense fallback={<Fallback />}>
    <LazyNotePage />
  </Suspense>
);

const LazyIphonePage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      /* webpackChunkName: "page/iphone" */
      '@vz/ui/pages/iphone/index'
    )
);

export const IphonePage = () => (
  <Suspense fallback={<Fallback />}>
    <LazyIphonePage />
  </Suspense>
);

const LazyLumiaPage = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      /* webpackChunkName: "page/lumia" */
      '@vz/ui/pages/lumia/index'
    )
);

export const LumiaPage = () => (
  <Suspense fallback={<Fallback />}>
    <LazyLumiaPage />
  </Suspense>
);
