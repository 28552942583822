import React, { Component, ErrorInfo } from 'react';
import { Props, State } from './types';

export default class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // console.log('>>>', { error });
    return { error };
  }

  // componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  //   console.log('>>>', { error, errorInfo });
  // }

  render() {
    const { error } = this.state;
    if (!error) {
      return this.props.children;
    }

    return (
      <div>
        Error: {error.message}
        <pre>
          <code>{error.stack}</code>
        </pre>
      </div>
    );
  }
}
