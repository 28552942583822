import React, { lazy, Suspense } from 'react';

const LazyBlogPostEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page/admin/blog/edit" */ '@vz/ui/pages/blog/(admin)/edit'
    )
);
export const BlogPostEditPage = () => (
  <Suspense fallback={null}>
    <LazyBlogPostEditPage />
  </Suspense>
);

const LazyDemoEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page/admin/demos/edit" */ '@vz/ui/pages/demos/(admin)/edit'
    )
);
export const DemoEditPage = () => (
  <Suspense fallback={null}>
    <LazyDemoEditPage />
  </Suspense>
);

const LazyNoteEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "page/admin/note/edit" */ '@vz/ui/pages/notes/(admin)/edit'
    )
);
export const NoteEditPage = () => (
  <Suspense fallback={null}>
    <LazyNoteEditPage />
  </Suspense>
);
