import * as React from 'react';
import { createRoot } from 'react-dom/client';
// import { hydrateRoot } from 'react-dom/client';
import initLazySentry from '@3ui/dev-sentry/lazy';

import { App } from './App';

import './styles.css';

const container = document.getElementById('__root');
const root = createRoot(container!);
root.render(<App />);

// hydrateRoot(container!, <App />);

// Initialise Sentry

initLazySentry();
