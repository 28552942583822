// compare with https://www.npmjs.com/package/slugify for the matter of size and performance
export const getSlug = (
  str,
  { delimiter = '-', allowed = '-abcdefghijklmnopqrstuvwxyz' } = {}
) => {
  return str
    .toLowerCase()
    .split('')
    .filter((ch) => ch === ' ' || allowed.includes(ch))
    .join('')
    .replaceAll('-', ' ')
    .split(' ')
    .filter(Boolean)
    .join(' ')
    .trim()
    .replace(/\s+/g, delimiter);
};
