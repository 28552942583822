export const __PROD__ = process.env.NODE_ENV === 'production';
export const __DEV__ = !__PROD__;

export const __ADMIN__ = process.env.ADMIN === 'true';

export const WEBSITE =
  process.env.WEBSITE || process.env.VERCEL_URL
    ? `https://${process.env.WEBSITE || process.env.VERCEL_URL}`
    : `http://localhost:3010`;

export const IS_SERVER = typeof process.env.VERCEL !== 'undefined';

export const CACHE_TIME = process.env.CACHE_TIME || 5; // 30s
