import React, { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import cx from '@3ui/cx';

import { Plus as IconPlus, Loader as IconLoader } from 'react-feather';

import { useNavigate } from 'react-router-dom';

import { useGet, usePost } from '../../hooks/useSWR';

import S from './AddButton.module.css';

interface Props {
  className?: string;
  api: string;
  goto?: string;
  refreshApi?: string;
}

export const AddButton = ({ className, api, goto, refreshApi }: Props) => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const { trigger, isMutating } = usePost(api);

  const handleClick = useCallback(() => {
    trigger(
      {},
      {
        onSuccess(reponse, key, config) {
          console.log({ reponse, key, config });
          const id = reponse?.data?.id;
          if (goto && id) {
            navigate(goto.replaceAll('{{id}}', id));
          }
          refreshApi && mutate(refreshApi);
        }
      }
    );
  }, [trigger, navigate, goto, refreshApi]);

  return (
    <button
      onClick={handleClick}
      disabled={isMutating}
      className={cx(S.root, className)}
    >
      {isMutating ? (
        <IconLoader width={24} height={24} />
      ) : (
        <IconPlus width={24} height={24} />
      )}
    </button>
  );
};
