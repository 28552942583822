import React, { HTMLAttributes } from 'react';

import cx from '@3ui/cx';

import S from './Spinner.module.css';

type Props = HTMLAttributes<HTMLDivElement> & {
  className?: string;
  size?: number;
};
export const Spinner = ({ className, size, ...domProps }: Props) => (
  <div
    {...domProps}
    className={cx(S.root, className)}
    style={{ ...(domProps.style || {}), width: size, height: size }}
  />
);
