import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Pages } from './pages';
// import { DisqusProvider } from '../Comments';

// import './App.global.css';

import { Layout } from '@vz/ui/components/Layout';
import { Sidebar } from '@vz/ui/components/Sidebar';

import './styles.css';

export const App = () => (
  //   <DisqusProvider shortname="vasyl-zubach">
  <BrowserRouter>
    {/* <div>test</div> */}
    <HelmetProvider>
      <Layout sidebar={Sidebar}>
        <Pages />
      </Layout>
    </HelmetProvider>
  </BrowserRouter>
  //   </DisqusProvider>
);

// export default App;
