// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Admin-add__L3Zlc {\n  position: absolute;\n  display: flex;\n  width: 24px;\n  height: 24px;\n  background: #f0f0f0;\n  align-items: center;\n  justify-content: center;\n  /* right: -5px; */\n  /* top: 5px; */\n  border-radius: 3px;\n  opacity: 0.3;\n  transition: opacity 0.2s, right 0.2s;\n  z-index: 50;\n\n  right: 3px;\n  top: 3px;\n\n  &:hover {\n    opacity: 1;\n    background: #e3e3e3e;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../ui/components/DemosList/Admin.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,oCAAoC;EACpC,WAAW;;EAEX,UAAU;EACV,QAAQ;;EAER;IACE,UAAU;IACV,oBAAoB;EACtB;AACF","sourcesContent":[".add {\n  position: absolute;\n  display: flex;\n  width: 24px;\n  height: 24px;\n  background: #f0f0f0;\n  align-items: center;\n  justify-content: center;\n  /* right: -5px; */\n  /* top: 5px; */\n  border-radius: 3px;\n  opacity: 0.3;\n  transition: opacity 0.2s, right 0.2s;\n  z-index: 50;\n\n  right: 3px;\n  top: 3px;\n\n  &:hover {\n    opacity: 1;\n    background: #e3e3e3e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add": "Admin-add__L3Zlc"
};
export default ___CSS_LOADER_EXPORT___;
