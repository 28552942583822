import useSWRModule, { type SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import window from 'window-or-global';

const fetcher = (url) => fetch(url).then((res) => res.json());

export const useSWR = (
  url,
  {
    initial: fallbackData,
    ...options
  }: SWRConfiguration & { initial?: unknown } = {}
) => {
  const result = useSWRModule(url, fetcher, {
    fallbackData,
    revalidateIfStale: false,
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 0,
    focusThrottleInterval: 10000,
    keepPreviousData: false,
    onSuccess(data, key) {
      if (!window.__INITIAL_DATA__) {
        window.__INITIAL_DATA__ = {};
      }
      window.__INITIAL_DATA__[key] = data;
    },
    ...options
  });
  return result;
};

export const useGet = useSWR;

// ----
const postRequest = async (url, { arg, headers = {} }) =>
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
    headers: {
      'Content-Type': 'application/json',
      ...headers
    }
  }).then((res) => res.json());

export const usePost = (url) => useSWRMutation(url, postRequest);
// export const useDelete = (url) => useSWRMutation('/api/user', postRequest);
